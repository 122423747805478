#compareOne .text,
.compare-link {
    font-size: 0.8rem;
}
.is-in-compare .material-icons {
    box-shadow: 0px 0px 0px 2px $color-polar;
    border-radius: 50%;
}
.block-compare {
    margin: 1rem 0;
    #compare-items {
        .item {
            display: flex;
            margin: 0.25rem 0;
            background: #fafafa;
            border-radius: 3px;
            padding: 0.25rem;
            p {
                display: inline-block;
                order: 1;
                margin: auto 0.5rem;
            }
        }
    }
    .actions {
        margin-top: 0.5rem;
        display: flex;
        a {
            margin-left: auto;
            padding: 0.5rem 1rem;
            border: 2px solid $color-polar;
        }
    }
}

.page-popup {
    background: white;
    padding: 2rem;
    h1 {
        font-weight: lighter;
    }
    .buttons-set {
        display: flex;
        .button {
            margin-left: auto;
        }
    }
    .btn-cart {
      width: 100%;
      margin: 1rem 0;
      padding: 1rem;
    }
    .link-wishlist {
        display: none;
    }
    .add-to-row {
        .price-box {
            min-height: 4rem; 
        }
    }
    .compare-table {
        background: white;
        margin: 1rem 0;
        border-collapse: collapse;
        border: 1px solid #fafafa;
        .product-shop-row.top.first td {
            text-align: center;
        }
        h2.product-name {
            font-size: 1.25rem;
            min-width: 300px;
        }
        p {
            margin: 0;
        }
        td, th {
            padding: 0.75rem;
            text-align: left;
            border-bottom: #f0f0f0 1px solid;
        }
        th {
            background: #fafafa;
            color: #666;
        }
        td:nth-child(odd) {
            background: #fafafa;
        }
        .price-box {
            text-align: center;
            font-weight: bold;
        }
    }
}
