@mixin legacy-button($button-text-color, $button-bg-color) {
  padding: 1em;
  border: none;
  outline: none;
  color: $button-text-color;
  text-transform: uppercase;
  background: $button-bg-color;
  &:hover {
    cursor: pointer;
    color: $button-text-color;
    background: darken($button-bg-color, 5%);
    text-decoration: none;
    transition: ease 0.2s background;
  }
}

@mixin core-button {
  margin-bottom: 0.5rem;
  font-weight: lighter;
  text-align: center;
  text-decoration: none;
  border: 0;
  &:disabled {
    background: $mdl-grey-500;
  }
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

@mixin basic-action-button($bg: $basic-action-button-bg, $color: $basic-action-button-color, $amount: 5%) {
  @include core-button;
  display: inline-block;
  color: $color;
  background: $bg;
  &:hover {
    background: darken($bg, $amount);
  }
}

@mixin action-cta-button($bg: $action-cta-button-bg, $color: $action-cta-button-color, $amount: 5%) {
  @include core-button;
  background: $bg;
  color: $color;
  margin-bottom: 0.5rem;
  &:hover {
    background: darken($bg, $amount);
  }
}

@mixin addtocart-button($bg: $add-to-cart-bg, $color: $addtocart-button-color, $amount: 5%) {
  @include core-button;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: 0;
  color: $color;
  font-weight: lighter;
  font-size: 1.2rem;
  text-align: center;
  background: $bg;
  i.material-icons {
    position: relative;
    top: 0.25em;
    padding-bottom: 0.25em;
  }
  &:hover {
    background: darken($bg, $amount);
  }
}
