@if $polarcore-review == true {

/* REVIEW - _review.scss */

#customer-reviews {
  .review-list > dl {
    dt {
      margin-top: 1em;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 1.5em;

      h4 {
        margin-bottom: 0;
      }
    }
    dd {
      padding: 1em;
      background: $review-list-bg-color;
      ul {
        margin: 1em 0;
        margin-top: 0.175em;

        li {
          line-height: 1;
        }
        i {
          color: $review-color;
        }
      }
    }
  }
}

#review-form {
  h3 {
    display: none;
  }
  thead {
    display: none;
  }
  input[type=radio],
  input[type=checkbox] {
    display: none;
  }
  .material-icons:hover {
    cursor: pointer;
  }
  input[type=radio]:checked ~ label {
    color: $review-color;
    transition: ease 0.5s;
    user-select: none;
  }
  input[type=radio] + label {
    color: $review-color;
    transition: ease 0.5s;
    user-select: none;
  }
  .highlight {
    color: $review-color;
    transition: ease 0.5s;
    & > i {
      color: $review-color;
      transition: ease 0.5s;
    }
  }
}

// END IF
}
