@if $polarcore-buttons == true {
    
    a.link-wishlist,
    a.link-compare,
    a.link-learn {
      text-decoration: none;
      &:hover {
        color: $basic-link-hover-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .button {
      @include legacy-button($button-text-color, $button-bg-color);
    }

    .btn-continue,
    .subscribe-button,
    #favoriteproducts-page-body .view-details,
    button[type=button],
    button[type=submit],
    .button {
        @include action-cta-button;
    }

    .btn-update,
    .btn-empty,
    a.favoriteproducts-add-button,
    .customer-account-login .mdl-button--raised,
    .button.share,
    .button.print,
    .button.newsletter,
    button[type=reset],
    .favoriteproducts-page-clear {
        @include basic-action-button;
    }

    .btn-proceed-checkout,
    .btn-checkout,
    .btn-cart,
    .button.btn-proceed-checkout,
    .button.btn-checkout,
    .button.btn-cart,
    a.cart,
    .cart.button,
    #favoriteproducts-add-to-cart {
        @include addtocart-button;
    }

    button,
    button.button,
    .button,
    a.button,
    .add-to-cart-wrapper a.favoriteproducts-add-button {
        transition: background 0.2s ease;
    }
}
