/* Slider */
.slick-slider {
  display: block;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  margin: auto;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #ffffff url("../images/ajax-loader.gif") center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
  display: block;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px\9; /*lte IE 8*/
  padding: 0;
  border: none;
  outline: none;
  color: transparent;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  background: transparent;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  z-index: 30 !important;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  color: transparent;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  opacity: 0.75;
  color: white;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "<";
}
[dir="rtl"] .slick-prev:before {
  content: ">";
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: ">";
}
[dir="rtl"] .slick-next:before {
  content: "<";
}

/* Dots */
.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  display: block;
  position: absolute;
  bottom: 5px;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 0;
  outline: none;
  color: transparent;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: black;
  font-size: 1rem;
  text-align: center;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  content: "\25CF";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

#polarcore .slick-slider {
  .slick-dots {
    position: static;
    bottom: initial;
  }

  .slick-track:before,
  .slick-track:after {
    font-family: initial;
  }

  .slick-slide {
    outline: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next,
  .slick-prev {
    display: flex;
    @media #{$medium-up} {
        display: flex !important;
    }
    z-index: 80;
    position: absolute;
    width: 3rem;
    height: auto;
    margin: auto;
    background: rgba(255, 255, 255, 0.45) !important;
    &:hover {
      background: rgba(0,0,0, 0.05) !important;
    }
  }

  .slick-prev:before {
    content: "\E314";
  }
  
  .slick-next:before {
    content: "\E315";
  }

  .slick-next:before,
  .slick-prev:before {
    display: inline-block;
    height: 100%;
    margin: auto;
    color: #222222;
    font-size: 3rem;
    font-family: "Material Icons" !important;
    font-family: initial;
    background: none;
  }
}

#photozoom.photozoom-position--vertical .slick-next:before {
  transform: rotate(90deg) !important; // PhotoZoom patch
}
