@if $polarcore-animation == true {

/* ANIMATION.scss */

@keyframes showsearch {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes hidesearch {
  0% {
    opacity: 1;
    transform: translateX(0x);
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

@keyframes smoothOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes polarcore-loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes load-spin {
  100%{ transform: rotate(360deg); transform: rotate(360deg); }
}
.polarcore__loading {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border: 6px double;
  border-radius: 50%;
  border-color: transparent lighten($loading-border-color, 5%) lighten($loading-border-color, 25%);
  animation: load-spin 960ms infinite linear;
}

// END IF
}
