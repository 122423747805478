@if $polarcore-header-nav == true {

/* HEADER NAV - _header-nav.scss */

.header-nav {
  display: none;
  text-transform: uppercase;
  background: $nav-background-link-color;
  @media #{$medium-up} {
    display: block;
  }
  #nav .nav-primary {
    @include clearfix();
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    .active {
      background: darken($nav-background-link-color, 15%);
    }
    .level1 .has-children {
      &:before {
        float: right;
        content: $nav-child-arrow;
      }
    }
    li {
      position: relative;
      margin: 0;
      padding: 0;
      list-style: none;
      white-space: nowrap;
      a {
        display: inline-block;
        width: 100%;
        padding: 0.75rem 1rem;
        color: $nav-text-link-color;
        font-weight: 100;
        font-size: 1.05rem;
        text-align: center;
        text-align: left;
        text-decoration: none;
      }
      @media #{$medium-only} {
        a {
          padding: 0.75rem;
          font-size: 1rem;
        }
      }
      &.menu-active,
      &:hover {
        @include material-animation-default();
        background: darken($nav-background-link-color, 25%);
      }
      &:focus {
        background: darken($nav-background-link-color, 25%);
      }
    }
    & > li ul {
      display: none;
      position: absolute;
      top: 100%;
      margin: 0;
      padding: 0;
      border-top: solid 2px darken($nav-background-link-color, 5%);
      border-bottom: solid 2px darken($nav-background-link-color, 25%);
      background: $nav-background-link-color;
      li:hover {
        background: darken($nav-background-link-color, 25%);
      }
    }
    li > ul {
      display: none;
      z-index: 3000;
      ul {
        position: absolute;
        top: 0;
        left: 100%;
        flex: 0 1 auto;
      }
    }
    li.menu-active > ul,
    li:hover > ul {
      display: block;
    }
  }
}

.nav-home-icon {
  vertical-align: middle;
}

// END IF ORIGINAL NAV
}
