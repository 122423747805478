/* WISHLIST - _wishlist.scss */

.my-wishlist {
  .page-title {
    text-align: center;
  }
  #wishlist-view-form {
    .buttons-set.buttons-set2 {
      & > button.button {
        width: 100%;
        margin: 0.25rem 0;
      }
    }
  }
  .polarcore-wishlist-table {
    display: flex;
    width: 100%;
    ul {
      width: 100%;
    }
    .polarcore-wishlist-item {
      display: flex;
      flex-direction: column;
      margin: auto;
      @media #{$medium-up} {
        flex-direction: row;
        height: 15rem;
      }
      .polarcore-wishlist-cell {
        margin: auto;
      }
      .customer-wishlist-item-quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        margin: 1rem auto;
        input {
          width: 100%;
          text-align: center;
        }
      }
      .customer-wishlist-item-info {
        width: 100%;
        @media #{$small-only} {
          .product-name {
            text-align: center;
          }
        }
        @media #{$medium-up} {
          width: 45%;
        }
        textarea {
          position: relative;
          width: 100%;
          min-width: 0rem;
          height: 4rem;
          min-height: 0rem;
        }
        .wishlist-sku {
          display: none;
        }
        .item-manage button {
          display: none;
        }
      }
      .customer-wishlist-item-cart {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        @media #{$medium-up} {
          width: 15%;
        }
        .truncated {
          position: relative;
          order: 1;
          width: 100%;
          .truncated_full_value {
            display: none;
            position: absolute;
            left: -50%;
            min-width: 200px;
            padding: 1rem;
            border: 1px solid #e2e2e2;
            background: white;
            box-shadow: 0 10px 20px rgba(0,0,0,0.5);
            &.show {
              display: block;
            }
          }
        }
        .cart-cell {
          order: 5;
          width: 100%;
          margin-top: 1rem;
          .btn-cart {
            width: 100%;
            margin-bottom: 0.5rem;
          }
          .price-label {
            display: none;
          }
        }
        & > p {
          order: 6;
          width: 100%;
          font-size: 0.8rem;
          a {
            display: inline-block;
            width: 100%;
            padding: 0.5rem;
            text-align: center;
          }
        }
      }
      .customer-wishlist-item-image {
        width: 6rem;
        @media #{$small-only} {
          order: 1;
        }
      }
      .customer-wishlist-item-remove {
        width: 2rem;
        height: 2rem;
        color: rgba(0,0,0,0);
        @media #{$small-only} {
          order: 5;
          margin-top: 1rem;
        }
        a span {
          display: none;
        }
      }
      .customer-wishlist-item-price {
        @include grid-price-box(column, center);
      }
    }
  }
}
