@if $polarcore-mobile-nav == true {

/* MOBILE NAV - _mobile-nav.scss */

.mdl-layout__container {
  position: relative;
  width: auto;
  height: auto;
  .mdl-layout {
    overflow-x: visible;
    overflow-y: visible;
  }
}

.mdl-layout__drawer-button {
  display: block;
  z-index: 350;
  width: auto;
  max-height: 3rem;
  color: black;
  line-height: auto;
  .menu-label {
    display: block;
    position: relative;
    margin-top: -2.8rem;
    font-size: 0.54rem;
    @media #{$medium-up} {
      display: none;
    }
  }
  @media #{$medium-up} {
    display: none;
  }
  i.material-icons {
    padding: 0.5rem;
    font-size: 2rem;
  }
}
body.popup-open {
  position: fixed;
  overflow: hidden;
  .mdl-layout__drawer-button {
    z-index: initial;
  }
}

.mdl-layout__drawer {
  z-index: 400;
  position: fixed;
  border-right: none;
  overflow-y: auto;
  background: $mobile-nav-top-links-bg;
  .mdl-layout__drawer__home {
    position: absolute;
    top: 0.35rem;
    left: 0.5rem;
    padding: 0.25rem;
    color: $mobile-text-color__drawer__home; // white
    font-size: 2rem;
  }
  .mdl-layout__drawer__title {
    margin: 0;
    padding: 1rem 0;
    color: $mobile-text-color__drawer__title; // white
    font-weight: 300;
    font-size: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background-color: $mobile-background-color__drawer__title; // color-polar
  }
  .mdl-layout__drawer__close {
    position: absolute;
    top: 0.35rem;
    right: 0.5rem;
    padding: 0.25rem;
    color: $mobile-text-color__drawer__close; // white
    font-size: 2rem;
  }
  #nav-mobile {
    padding: 0 !important;
    background: $nav-mobile-background-color; // white
    .has-children {
      &:after {
        float: right;
        content: $nav-child-arrow;
      }
    }
    .open > .has-children {
      &:after {
        float: right;
        content: $nav-child-arrow-open;
      }
    }
  }
  > ul,
  .nav-primary {
    li {
      ul {
        display: none;
        background-color: $mobile-nav-primary-background-color; // #f4f4f4
        /* Level 2 Indentation*/
        > li {
          > a {
            padding-left: 1.5rem;
          }
          > ul {
            > li > a {
              padding-left: 3rem;
            }
          }
        }
      }
      a {
        display: block;
        padding: 1rem;
        border-bottom: 1px solid rgba(0,0,0, 0.05);
        font-size: 1.1rem;
      }
      &.open,
      &.open > a {
        border-bottom: 1px solid $mobile-nav-primary-open-border-bottom-color; // color-polar
      }
      &.nav-home {
        display: none;
      }
    }
  }
}

ul.top-links__mobile {
  background: $mobile-nav-top-links-bg;
  &:first-of-type {
    border-top: 3px solid $mobile-nav-top-links-border-top-color; // color-polar
  }

  li {
    border-bottom: 1px solid rgba(0,0,0, 0.2);
  }
}

// END IF
}
