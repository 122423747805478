/* Material Design Lite */

// Variables and mixins
@import "lib/mdl/src/variables";
@import "lib/mdl/src/mixins";

// Resets and dependencies
@import "lib/mdl/src/resets/resets";
@import "lib/mdl/src/typography/typography";

// Components
//@import "lib/mdl/src/palette/palette";
@import "lib/mdl/src/ripple/ripple";
@import "lib/mdl/src/animation/animation";
//@import "lib/mdl/src/badge/badge";
@import "lib/mdl/src/button/button";
@import "lib/mdl/src/card/card";
@import "lib/mdl/src/checkbox/checkbox";
@import "lib/mdl/src/data-table/data-table";
@import "lib/mdl/src/dialog/dialog";
//@import "lib/mdl/src/footer/mega_footer";
//@import "lib/mdl/src/footer/mini_footer";
//@import "lib/mdl/src/icon-toggle/icon-toggle";
@import "lib/mdl/src/list/list";
//@import "lib/mdl/src/menu/menu";
@import "lib/mdl/src/progress/progress";
@import "lib/mdl/src/layout/layout";
@import "lib/mdl/src/radio/radio";
@import "lib/mdl/src/slider/slider";
@import "lib/mdl/src/snackbar/snackbar";
@import "lib/mdl/src/spinner/spinner";
@import "lib/mdl/src/switch/switch";
@import "lib/mdl/src/tabs/tabs";
@import "lib/mdl/src/textfield/textfield";
@import "lib/mdl/src/tooltip/tooltip";
@import "lib/mdl/src/shadow/shadow";
@import "lib/mdl/src/grid/grid";
