@if $import-ajaxcart == true {
  /* MODULES / AJAXCART - modules/ajaxcart/_ajaxcart.scss */
  #polarcore {
    #ajaxcart,
    #ajaxcart-loading,
    #ajaxcart-error {
      top: 50%;
      left: 50%;
      margin-left: auto;
      filter: blur(0);
      transform: translate(-50%, -50%);
      font-smoothing: subpixel-antialiased;
      box-shadow: 0 20px 170px rgba(0, 0, 0, 0.25);
      border: 1px solid #e2e2e2;
      background: #fff;
      color: #333;

      h2 {
        font-size: 1.2rem;

        @media #{$medium-up} {
          font-size: 1.575rem;
        }
      }

      .product {
        padding: 6px 0;
      }

      .actions {
        display: flex;
        flex-direction: column;

        .cart.button {
            background-image: none;
            background: $add-to-cart-bg;
            padding: 1rem;
            margin-top: 0;
        }
        .button,
        button {
          flex: 1;
          margin-right: 0;

          &:not(:last-child) {
            margin-bottom: 0.5rem;

            @media #{$medium-up} {
              margin-right: 0.5rem;
              margin-bottom: 0;
            }
          }
        }
        @media #{$medium-up} {
          flex-direction: row;
        }
      }
    }
    #ajaxcart-error-msg {
        margin: 1rem 0;
        padding: 1rem;
        color: white;
        background: $error-msg-bg-color;
    }
    #ajaxcart-error {
      .continue {
        @extend .button;
      }
    }
  }

  #polarcore {
    #ajaxcart-loading .close,
    #ajaxcart .close,
    #ajaxcart-error .close {
      top: 0.5rem;
      right: 0.5rem;
      padding: 0;
      padding-top: 6px;
      padding-left: 1px;
      border-radius: 0;
      text-indent: -999px;
      background: transparent;
      font-size: 0;
      &:before {
        @include material-icons();
        position: absolute;
        top: 0;
        right: 0;
        color: $color-polar;
        font-size: 1.5rem;
        text-indent: 0;
        content: "\E5CD";
      }
    }
    #ajaxcart .actions button,
    #ajaxcart .actions .button {
      font-size: 1rem;
    }
  }
}
