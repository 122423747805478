@import "mixins/buttons",
        "mixins/family",
        "mixins/modals";

@mixin clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

@mixin show-hide-flex($param: false) {
  @if $param == true { 
    display: flex;
  } @else {
    display: none;
  }
}

@mixin add-to-links() {
  @include show-hide-flex($add-to-links);
  padding: 0;
  list-style: none;
  .link-compare {
    @include show-hide-flex($add-to-links-compare);
  }
  .link-wishlist {
    @include show-hide-flex($add-to-links-wishlist);
  }
  li {
    padding: 1rem 0.5rem;
  }
  .inner {
    span {
      display: none;
    }
  }
}

@mixin material-icons {
  display: block;
  overflow: hidden;
  font-style: normal;
  line-height: 1;
  font-family: "Material Icons";
  text-transform: none;
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}

@mixin addtocart-button($bg: $add-to-cart-bg, $color: #fff, $amount: 5%) {
  @include core-button;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: 0;
  color: $color;
  font-weight: lighter;
  font-size: 1.2rem;
  text-align: center;
  background: $bg;
  i.material-icons {
    position: relative;
    top: 0.25em;
    padding-bottom: 0.25em;
  }
  &:hover {
    cursor: pointer;
    background: darken($bg, $amount);
  }
}

@mixin h2-with-line {
  // <div class="title">
  //    <h2><span class="center">Title Of The Day</span></h2>
  // </div>
  position: relative;
  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    border-bottom: $h2-with-line-color 1px solid;
    content: "";
  }
  h2 {
    position: relative;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
    .center {
      padding: 0 2rem;
      background: $main-container-bg-color;
    }
  }
}

@mixin price-align($justify-content) {
  padding-left: 0;
  @if $justify-content == center {
    @media #{$large-up} {
      padding-left: 0.5rem;
    }
  }
}

@mixin grid-price-box($direction: row, $justify-content: center) {
  .price-box {
    display: flex;
    flex-direction: column;
    justify-content: $justify-content;
    align-items: $justify-content;
    width: 100%;
    height: auto;
    @media #{$large-up} {
      flex-direction: $direction;
      height: 1.8rem;
    }
    .price {
      margin: 0;
      font-style: lighter;
      font-size: 1.2rem;
    }
    .price-label {
      display: none;
      color: $price-color;
    }
    .regular-price {
      margin: 0;
      color: $price-color;
    }
    .old-price {
      order: 5;
      margin: 0;
      color: $old-price-color;
      .price {
        @include price-align($justify-content);
        text-decoration: line-through;
      }
    }
    .special-price {
      order: 1;
      margin: 0;
      color: $special-price-color;
      font-weight: bold;
    }
    .price-from {
      margin: 0;
      color: $price-color;
      font-size: 1rem;
      .price-label {
        display: inline;
        text-transform: uppercase;
      }
    }
    .price-to {
      display: none;
      margin: 0;
      color: $price-color;
    }
    .minimal-price {
      margin: 0;
      color: $price-color;
    }
    .minimal-price-link {
      @include price-align($justify-content);
      .label {
        display: none;
      }
    }
    .map-link {
      @include price-align($justify-content);
      order: 10;
      margin: 0;
      font-size: 1rem;
    }
    // End Price Box
  }
}
