@if $import-bubblelayer == true {
  /* MODULES / BUBBLE LAYER - modules/bubblelayer/_bubblelayer.scss */
  /* PolarCore Bubblelayer Module Style */

  $bubble-loading-border-color: $color-polar !default;
  $bubble-loading-text-color: $color-polar !default;

  #polarcore {
    .bubble-infinite-trigger a {
      @extend .button;
      display: block;
      color: #ffffff;
      margin: 1rem 0;
      width: 100%;
      &:hover {
        color: #ffffff;
      }
    }
    .bubble-infinite-trigger.bubble-loading a {
      background: transparent;
      color: $bubble-loading-text-color;
      &:hover {
        color: $bubble-loading-text-color;
      }
      &:before {
        display: block;
        content: "";
        width: 30px;
        height: 30px;
        margin: 1rem auto;
        border: 11px double;
        border-radius: 50%;
        border-color: transparent lighten($bubble-loading-border-color, 5%) lighten($bubble-loading-border-color, 25%);
        animation: load-spin 960ms infinite linear;
      }
    }
    @media #{$small-only} {
      &.popup-open {
        .block-layered-nav {
          z-index: 100;
          position: relative;
        }
      }
    }
    .block-layered-nav--no-filters {
      display: block;
    }
    .block-layered-nav {
      .currently {
        @media #{$medium-up} {
          margin-bottom: 0;
          padding-right: 1rem;
        }
        .block-subtitle {
          font-weight: 700;
        }
        ol {
          li {
            display: flex;
            width: 100%;
          }
        }
      }
      #narrow-by-list dt {
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-size: 1rem;
        text-transform: uppercase;
        @media #{$medium-up} {
          &.expanded {
            cursor: pointer;
            i {
              @include material-icons();
              display: inline-block;
              margin-bottom: -0.15rem;
              margin-left: 0.25rem;
              font-size: 1.5rem;
              line-height: 20px;
              vertical-align: text-bottom;
              content: "\E315";
              transition: transform 0.25s ease;
            }
          }
          &.collapsed {
            cursor: pointer;
            i {
              @include material-icons();
              display: inline-block;
              margin-bottom: -0.15rem;
              margin-left: 0.25rem;
              font-size: 1.5rem;
              line-height: 20px;
              vertical-align: text-bottom;
              content: "\E315";
              transition: transform 0.25s ease;
              transform: rotate(90deg);
            }
          }
        }
      }
      #narrow-by-list dd {
        ol {
          margin: 0;
          border-bottom: 0;
          li {
            display: flex;
            margin: 0.5rem 0;
            color: #cccccc;
            font-size: 0.9rem;
            width: 100%;
            .mdl-checkbox {
              width: 100%;
              input[type=checkbox] {
                opacity: 0;
                width: 16px;
              }
              &:not(.is-upgraded) {
                .mdl-checkbox__label {color: #888888;}
              }
              &.is-upgraded .mdl-checkbox__box-outline {
                animation: smoothOpacity 0.15s ease;
              }
              .mdl-checkbox__label {
                font-size: 1rem;
              }
            }
            .count {
              color: #bbbbbb;
              font-size: 0.8rem;
            }
            input {
              cursor: pointer;
            }
          }
        }
        .label-filter a:hover,
        .label-filter a:focus {
          color: white;
          background-color: $color-polar;
        }
        &.actions {
          z-index: 100;
          position: fixed;
          top: 100%;
          left: 0;
          width: 100%;
          transform: translateY(-100%);
          .mobil-apply-btn {
            display: block;
            width: 100%;
            padding: 1rem;
            border-top: 4px solid white;
            color: white;
            font-size: 1.15rm;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 1px;
            background-color: $button-bg-color;
            transform: translateZ(0);
          }
        }
        li {
          @media #{$small-only} {
            .mdl-checkbox__focus-helper,
            .mdl-checkbox__box-outline {
              width: 20px;
              height: 20px;
              vertical-align: middle;
            }
          }
        }
        .layer-slider {
          .price-slider {
            @media #{$small-only} {
              display: block;
              margin: 2rem 1rem;
              input[type=text] {
                font-size: 16px;  
              }
              .handle {
                transform: scale(2);
              }
            }
            .handle {
              top: 3px;
              width: 16px;
              height: 16px;
              background-color: $color-polar;
            }
            .span {
              background-color: $color-polar;
            }
          }
          .price-range {
            @include clearfix();
            color: $color-polar;
            text-align: center;
            .price {
              float: left;
              @media #{$small-only} {
                margin-left: 0.25rem;
              }
            }
            .price:last-of-type {
              float: right;
              @media #{$small-only} {
                margin-right: 0.25rem;
              }
            }
            div {
              display: table;
              width: 100%;
              input {
                float: left;
                margin: 0;
              }
              input:last-of-type {
                float: right;
              }
            }
          }
          .price-limit {
            display: none;
          }
        }
        @media #{$small-only} {
          ol,
          ul,
          div {
            margin-right: 0.75rem;
          }
        }
        ol {
          border-bottom: 0;
          li {
            margin: 0.5rem 0;
            color: #cccccc;
            font-size: 0.9rem;
            .count {
              color: #bbbbbb;
              font-size: 0.8rem;
            }
            input {
              cursor: pointer;
            }
          }
        }
        li.is-checked a,
        li.active a {
          border-radius: 3px;
          outline: 0;
          color: white;
          text-decoration: none;
          background-color: $color-polar;
          .count {
            background-color: white;
          }
        }
        @media #{$small-only} {
          a:hover {
            background-color: #eeeeee;
          }
        }
      }
      @media #{$small-only} {
        #narrow-by-list {
          @include clearfix();
          padding: 5rem 0.75rem;
          overflow-y: auto;
          dt {
            margin: 0;
            padding: 1rem 1rem;
            border-bottom: 1px solid #f0f0f0;
            i {
              float: right;
            }
            &.open i {
              transform: rotate(90deg);
            }
            &:not(.open) {
                @include from-end(4) {
                    margin-bottom: 3rem;
                }
            }
          }
          dd {
            display: none;
            padding: 0.75rem 1rem;
            background-color: #fafafa;
            @include from-end(3) {
                margin-bottom: 3rem;
            }
            .no-display {
              display: flex !important;
            }
            .show-hidden {
              display: none !important;
            }
          }
          h2.headline {
            position: absolute;
            top: 1rem;
            left: 4px;
            width: 85%;
            margin: 0;
            padding: 0.25rem 0.75rem;
            color: #555555;
            font-weight: bold;
            font-size: 1.3rem;
            line-height: 1.75rem;
            text-transform: uppercase;
          }
        }
      }
      #narrow-by-list dd li:hover {
        //background-color: #f0f0f0;
      }
      .block-content {
        display: flex;
        flex-direction: column;
      }
      .block-title,
      .block-subtitle,
      .actions {
        display: none;
      }
      .currently {
        order: 100;
        margin-bottom: 36px;
        @media #{$medium-up} {
          order: 0;
        }
        .block-subtitle {
          margin: 0.75rem 0 0 0;
        }
        ol li {
          display: inline-block;
          position: relative;
          margin: 2px 0;
          padding: 4px 24px 4px 12px;
          border: 1px solid #ededed;
          border-radius: 6px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          background: #ededed;
          @media #{$medium-up} {
            display: block;
            margin: 6px 0;
          }
        }
        .btn-remove {
          position: absolute;
          right: 0.5rem;
          font-size: 11px;
          span {
            display: none;
          }
          &:after {
            @include material-icons();
            position: relative;
            top: 0.2rem;
            margin-left: 0.5rem;
            font-size: 1.25rem;
            content: "\E14C";
          }
        }
      }
      /* Mobile Popup Styling*/
      @media #{$small-only} {
        .block-subtitle {
          display: block;
          cursor: pointer;
          &:hover {
            cursor: pointer;
          }
        }
        .block-subtitle--filter:not(.active) + #narrow-by-list {
          display: none;
        }
        #narrow-by-list {
          z-index: 100;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 4rem 0rem 3rem;
          background-color: #ffffff;
        }
      }
    }
    .bubble-layer-top {
      display: none;
      @media #{$medium-up} {
        display: block;
      }
      .block-layered-nav {
        .block-content {
          @media #{$medium-up} {
            max-width: none;
          }
        }
      }
    }
  }
  @media #{$small-only} {
    #polarcore:not(.popup-open) .block-subtitle--filter {
        & + #narrow-by-list {
            display: none !important;
        }
    }
    #polarcore.popup-open .block-subtitle--filter {
      z-index: 105;
      position: fixed;
      top: 0.5rem;
      right: 0.5rem;
      width: 3rem;
      height: 3rem;
      overflow: hidden;
      text-indent: -999px;
      &:before {
        @include material-icons();
        position: absolute;
        top: 10px;
        right: 2px;
        font-size: 1.5rem;
        text-indent: 0;
        content: "\E5CD";
      }
    }
  }
}
