@if $polarcore-checkout == true {

/* CHECKOUT - _checkout_scss */

.checkout-onepage-index {
  button.button.btn-cart {
    display: block;
  }
  #checkout-progress-wrapper {
    text-transform: uppercase;
    dt.complete,
    dt {
      color: #444444;
      font-weight: 300;
      font-size: 1.2rem;
    }
    dd.complete {
      margin: 1rem 0;
      color: #666666;
      address {
        line-height: 1.5;
      }
    }
  }
  h1 {
    font-weight: 300;
    text-transform: uppercase;
  }
  .section {
    .step-title {
      display: flex;
      position: relative;
      text-transform: uppercase;
      h2 {
        display: inline;
        margin: 0.5rem 0;
        color: #cccccc;
        font-weight: 200;
        line-height: 1.2em;
      }
      .number {
        display: inline;
        margin: auto 0;
        padding: 1rem;
      }
      a {
        display: none;
      }
    }
    &.active {
      padding: 1rem;
      border: 5px solid #f0f0f0;
      h2 {
        color: #444444;
      }
    }
    &.active .step {
      display: block;
    }
    .col2-set {
      display: flex;
      flex-direction: column;
      .col-1,
      .col-2 {
        width: 100%;
        padding: 1rem;
        form {
          margin-bottom: 1rem;
        }
        h3 {
          font-weight: 300;
          font-size: 1.5rem;
          line-height: 1.4em;
          text-transform: uppercase;
        }
      }
      @media #{$large-up} {
        flex-direction: row;
        .col-1 {
          width: 50%;
        }
        .col-2 {
          width: 50%;
        }
      }
    }
  }
  .section.active {
    display: block;
  }
  #checkout-review-table {
    width: 100%;
    text-transform: uppercase;
  }
  #review-buttons-container {
    .btn-checkout {
      margin: 1rem 0;
    }
  }
  .mdl-textfield {
    .validation-advice {
      font-size: 0.7rem;
    }
  }
}
.checkout-onepage-succes {
  .logo {
    display: inline-block;
    max-width: 20rem;
  }
  .main-container {
    padding: 1rem;
  }
  h1,
  h2,
  h3 {
    font-weight: 300;
  }
}

// END IF
}
