@if $polarcore-typography == true {

/* TYPOGRAPHY - _typography.scss */

h1 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: $heading-h1;
  font-family: $heading_font;
}

h2 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: $heading-h2;
  font-family: $heading_font;
}

h3 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: $heading-h3;
  font-family: $heading_font;
}

h4 {
  font-size: $heading-h4;
  font-family: $heading_font;
}

button {
  font-family: $preferred_font;
}
p {
  line-height: 1.5rem;
}

// END IF
}
