@if $polarcore-product-grid == true {

/* PRODUCT-GRID - _product-grid.scss */

.products-grid {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch;
  padding: 0;
  .actions {
    text-align: center;
    .add-to-links {
      @include add-to-links();
    }
  }
  button.btn-cart {
    display: none;
  }
  .combo-box {
    width: 100%;
    .review-wrapper {
      width: 100%;
      text-align: center;
      .amount {
        position: relative;
        bottom: 0.1rem;
      }
    }
  }
  .configurable-swatch-list {
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      .swatch-label {
        img {
          border: 1px $configurable-swatch-list-border-color solid;
        }
      }
    }
  }
  .item {
    @include grid-price-box();
    padding: 3px;
    list-style: none;
    background: transparent;
    transition: box-shadow 0.15s ease;
    will-change: box-shadow;
    @media #{$medium-up} {
      &:hover {
        box-shadow: 0 0 0 3px $product-list-hover-color;
      }
    }
    .inner-item {
      .image-wrapper {
        position: relative;
        text-align: center;
        img,
        a {
          //display: inline-block;
        }
      }
    }
    .product-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      padding: 1em;
      //border-bottom: #F0F0F0 2px solid;
      .review-wrapper {
        //height: 2rem;
        font-size: 0.8em;
        .rating-box {
          display: inline-block;
          i {
            position: relative;
            top: 0.1em;
            color: $review-color;
            font-size: 1.5em;
          }
        }
      }
      .product-name {
        margin: 0;
        //text-transform: uppercase;
        padding-bottom: 0.5rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-align: center;
        text-decoration: none;
        a {
          color: $product-name-link-color;
          font-weight: $product-name-font-weight;
          text-decoration: none;
          &:hover {
            color: lighten($product-name-link-color, 25%);
          }
        }
      }
    }
    .product-info {
      text-align: left;
      .actions {
        .button.view-details {
          display: none;
        }
        .link-compare span,
        .link-wishlist span {
          // display: none;
        }
      }
    }
  }
}

// END IF
}
