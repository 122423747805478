@if $polarcore-breadcrumbs == true {

/* BREADCRUMBS - _breadcrumbs.scss */

.breadcrumbs {
  @media #{$small-only} {
    height: 3.5rem;
    padding: 0.75rem;
    overflow: hidden;
  }
  @media #{$medium-up} {
    padding: 0.25rem 0rem;
  }
  ul {
    width: 100%;
    margin: 0;
    //display: flex;
    padding: 0;
    @media #{$small-only} {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scolling: touch;
    }
  }
  li {
    display: inline-block;
    margin-bottom: 0.5rem;
    list-style: none;
    vertical-align: middle;
    @media #{$small-only} {
      padding-left: 0;
      white-space: nowrap;
      &:after {
        @include material-icons();
        display: inline-block;
        position: relative;
        top: 0.2rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        font-weight: bold;
        content: "\E315";
      }
      &:last-child {
        padding-right: 0;
        background-color: transparent;
        strong {
          padding-right: 0rem;
        }
        &:after {
          content: "";
        }
      }
      & > a {
        background: #f0f0f0;
      }
      & > a,
      & > strong {
        display: inline-block;
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
    strong {
      padding-left: 0;
      text-transform: uppercase;
    }
    @media #{$medium-up} {
      & > a {
        font-weight: lighter;
      }
      &:last-of-type {
        padding-left: 1rem;
      }
    }
    .mdl-button {
      min-width: auto;
    }
    &.home {
      padding: 0;
      @media #{$medium-up} {
        padding-left: 0;
      }
    }
    & > span {
      margin-left: 0.25rem;
      color: $color-grey-medium;
      @media #{$small-only} {
        display: none;
      }
    }
    & > strong {
      color: lighten($basic-link-color, 5%);
      color: black;
      font-weight: 500;
    }
  }
}

// END IF
}
