// Media query rarnges
$small-range: (0, 767px) !default;
$medium-range: (768px, 1024px) !default;
$large-range: (1025px, 1440px) !default;
$xlarge-range: (1441px, 1920px) !default;
$xxlarge-range: (1921px, 99999999px) !default;
$grid-tablet-breakpoint: lower-bound($medium-range) !default;
$grid-desktop-breakpoint: lower-bound($large-range) !default;
$screen: "only screen" !default;
$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;
$small-up: "#{$screen} and (min-width: #{lower-bound($small-range)})" !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;
$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;
$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

// -- Max width of content
$content-width: 1200px !default;

// -- Grid
$grid-phone-columns: 12 !default;
$grid-phone-gutter: 16px !default;
$grid-phone-margin: 16px !default;
$grid-tablet-columns: 12 !default;
$grid-tablet-gutter: 16px !default;
$grid-tablet-margin: 16px !default;
$grid-desktop-columns: 12 !default;
$grid-desktop-gutter: 16px !default;
$grid-desktop-margin: 16px !default;
$header-height: ("small-up": 52px, "medium-up": 112px) !default;

// -- Fonts
$preferred_font: "Roboto", "Helvetica", "Arial", sans-serif !default;
$performance_font: $preferred_font !default;
$heading_font: $preferred_font !default;

// -- Basic
$color-blue: $mdl-blue-500 !default;
$color-grey-lighter: $mdl-grey-50 !default;
$color-grey-light: $mdl-grey-200 !default;
$color-grey-medium: $mdl-grey-400 !default;
$color-grey-dark: $mdl-grey-500 !default;
$color-grey-darker: $mdl-grey-800 !default;

// -- MDL & Polarcore Primary Colors
$color-polar: $color-blue !default;
$color-primary: to-mdl($color-polar) !default;
$nav-background-link-color: $color-polar !default;
$nav-text-link-color: #ffffff !default;
$add-to-cart-bg: $mdl-green-A700 !default;
$add-to-cart-text-color: #ffffff !default;
$select-regular-color: $color-polar !default;
$select-hover-color: darken($color-polar, 25%) !default;

// -- General Colors
$body-bg-color: #f0f0f0 !default;
$table-border: #f0f0f0 !default;
$border-color: #e0e0e0  !default;
$loading-border-color: $color-polar !default;

// -- Buttons
$action-cta-button-color: #ffffff !default;
$action-cta-button-bg: $color-polar !default;
$basic-action-button-color: #ffffff !default;
$basic-action-button-bg: #f0f0f0 !default;
$addtocart-button-color: #ffffff !default;
$button-bg-color: $color-polar !default;
$button-text-color: #ffffff !default;

// -- Header
$header-content-bg: #ffffff !default;
$header-search-button-color: #000000 !default;
$header-cart-icon-color: #000000 !default;
$header-cart-count-bg: $mdl-green-A700 !default;
$header-cart-count-color: #ffffff !default;
$header-cart-details-color: $color-grey-darker !default;

// -- Main Container
$main-container-bg-color: #ffffff !default;
$h2-with-line-color: $mdl-grey-300 !default;
$category-button-bg: $select-regular-color !default;
$category-button-bg-hover: $select-hover-color !default;
$category-button-color: #ffffff !default;

// -- Footer
$footer-container-bg-color: #2c313e !default;
$footer-container-text-color: #ffffff !default;
$footer-h2-color: #c0c0c0 !default;
$footer-links-color: white !default;
$footer-links-hover-color:  #cccccc !default;
$footer-copyright-bg-color: $color-polar !default;
$footer-copyright-text-color: #ffffff !default;
$footer-newsletter-bg: #ffffff !default;
$footer-newsletter-input-color: #333333 !default;
$footer-block-title-color: #5c657d !default;
$footer-backtotop-icon-bg: $color-polar !default;

// -- Mobile Nav
$mobile-nav-top-links-bg: #2c313e !default;
$mobile-text-color__drawer__home: #ffffff !default;
$mobile-text-color__drawer__title: #ffffff !default;
$mobile-background-color__drawer__title: #ffffff !default;
$mobile-text-color__drawer__close: #ffffff !default;
$nav-mobile-background-color: #ffffff !default;
$mobile-nav-primary-background-color: #f4f4f4 !default;
$mobile-nav-primary-open-border-bottom-color: $color-polar !default;
$mobile-nav-top-links-border-top-color: $color-polar !default;

// -- Sidebar
$sidebar-bg-color: #f5f5f5 !default;

// -- Product List
$product-list-hover-color: #f0f0f0 !default;
$add-to-links: false !default;
$add-to-links-compare: true !default;
$add-to-links-wishlist: true !default;

// -- Products View
$article-sku-color: #bbbbbb !default;
$product-name-font-weight: 400 !default;
$product-name-link-color: $color-grey-darker !default;
$products-list-border-bottom-color: $color-grey-light !default;
$configurable-swatch-list-border-color: #eeeeee !default;
$product-qty-input-bg: darken($add-to-cart-bg, 15%);
$product-qty-input-color: #ffffff;

// -- Account Page
$account-bg: #fafafa !default;
$account-panel-bg: #fafafa !default;
$account-title-color: darken($color-polar, 15%) !default;


// -- Polar Language List
$polarcore-language-bg-color: white !default;

// -- Messages Colors
$error-msg-text-color: white !default;
$error-msg-bg-color: $mdl-red-400 !default;
$success-msg-text-color: white !default;
$success-msg-bg-color: $mdl-green-400 !default;
$notice-msg-text-color: $mdl-grey-800 !default;
$notice-msg-bg-color: $mdl-yellow-600 !default;
$messages-bg-color: #fafafa !default;
$messages-text-color: $mdl-grey-800 !default;
$messages-border-color: #f0f0f0 !default;

// -- Category Settings
$display-asc-desc: none !default; // inline or none
$display-sort-by-label: inline !default; // inline or none
$display-view-mode: inline !default; // inline or none !default;

// -- Category/Products Colors
$special-price-color: red !default;
$old-price-color: #333333 !default;
$price-color: $mdl-grey-600 !default;

// -- General Elements
$text-link-color: $color-polar !default;
$search-border-color: $color-grey-light !default;
$review-color: #fed227 !default;
$review-list-bg-color: #fafafa !default;

// -- Top Line
$top-line-background: $color-grey-lighter !default;
$top-line-link-color: $color-grey-dark !default;
$top-line-border: $color-grey-light !default;
$basic-link-color: lighten($color-polar, 5%) !default;
$basic-link-hover-percentage: 25% !default;
$basic-link-hover-color: darken($basic-link-color, $basic-link-hover-percentage) !default;

// -- CART
$btn-checkout-unicode-arrow: "\E315" !default;
$cart-bg-color: #fafafa !default;

// -- NAVIGATION
$nav-child-arrow: " + " !default;
$nav-child-arrow-open: " - " !default;

// -- TYPOGRAPHY
$category-sidebar-title-text-transform: inherit !default;
$toolbar-text-transform: inherit !default;
$base-size: 1rem !default;
$ratio: 1.2 !default;
$heading-h5: $base-size * $ratio !default;
$heading-h4: $heading-h5 * $ratio !default;
$heading-h3: $heading-h4 * $ratio !default;
$heading-h2: $heading-h3 * $ratio !default;
$heading-h1: $heading-h2 * $ratio !default;


// -- Modules
$import-ajaxcart: true !default;
$import-algolia: false !default;
$import-bubblelayer: true !default;
$import-sidenav: false !default;
$import-tco: false !default;
$import-kco: false !default;
$import-cac: false !default;
$import-labels: false !default;
$import-quickshop: false !default;
$import-favoriteproducts: false !default;

// -- Modules Colors
$tco-color: $mdl-grey-800 !default;
