@if $polarcore-topline == true {

/* TOPLINE - _top-line.scss */

.top-line {
  @include clearfix();
  display: none;
  padding: 0.25rem 0;
  border-bottom: 1px solid $top-line-border;
  background-color: $top-line-background;
  @media #{$medium-up} {
    display: block;
  }
  .store-language-container {
    float: right;
    label {
      display: none;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline;
      padding: 0 0.5rem;
      a {
        color: $top-line-link-color;
        font-size: 0.9rem;
        text-decoration: none;
        &:hover,
        &:active {
          text-decoration: underline;
        }
      }
    }
    &.top-links-left {
      float: left;
    }
    &.top-links-right {
      float: right;
      margin-left: 1em;
    }
  }
}

// END IF
}
