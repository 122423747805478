@if $polarcore-form == true {

/* FORM - _form.scss */

input {
  padding: 0.8em;
  border: 1px solid $color-grey-light;
  outline: none;
}

textarea {
  width: 100%;
  max-width: 32rem;
  min-height: 16rem;
  padding: 0.8em;
  border: 1px solid $color-grey-light;
}

.validation-advice {
  // MDL Input fix
  position: absolute;
  color: $mdl-red-300;
  font-size: 0.8em;
}

// END IF
}
