@if $polarcore-cart == true {

/* CART - _cart.scss */

.checkout-cart-index {
  main {
    .page-title h1 {
      font-weight: 200;
      text-transform: uppercase;
    }
    .products-grid {
      button.btn-cart {
        @include addtocart-button;
        display: block;
        font-size: 1.1rem;
        &:before {
          @include material-icons();
          display: inline;
          margin-right: 1rem;
          vertical-align: bottom;
          content: "\E854";
        }
      }
    }
    .cart {
      h1 {
        float: left;
        margin: 0;
        margin-top: 0.5rem;
        padding: 0;
        font-weight: 300;
        text-transform: uppercase;
      }
      @media #{$small-only} {
        .checkout-types.top {
          display: none;
        }
      }
      .top .btn-checkout {
        display: none;
      }
      .btn-checkout {
        display: flex;
        float: right;
        clear: both;
        width: 100%;
        margin-top: 1rem;
        padding: 1rem 2rem;
        color: $add-to-cart-text-color;
        background: $add-to-cart-bg;
        transition: ease 0.2s background;
        span {
          margin: auto 0;
          font-size: 1.2rem;
          & > :after {
            @include material-icons();
            display: inline;
            margin-left: 0.5rem;
            font-weight: bold;
            font-size: 1.5rem;
            vertical-align: bottom;
            content: $btn-checkout-unicode-arrow;
          }
        }
        &:hover {
          background: darken($add-to-cart-bg, 5%);
        }
      }
    }
    #shopping-cart-table {
      li {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        margin: 1em 0;
        padding: 0.5em;
        background: $cart-bg-color;
        @media #{$medium-up} {
          flex-wrap: nowrap;
          & > * {
            margin: auto 1em;
          }
        }
        .downloadable-options {
          padding: 1rem;
          dt {
            display: block;
          }
          dd {
            display: inline-block;
          }
        }
        .product-image {
          width: 25%;
          margin: auto 0;
          @media #{$medium-up} {
            img {
              width: 8em;
              height: 8em;
            }
          }
        }
        .product-info {
          width: 75%;
          padding-left: 0.75rem;
          .product-name {
            color: $product-name-link-color;
            font-weight: lighter;
            font-size: 1.2rem;
            line-height: 1.8rem;
            @media #{$small-only} {
              padding-right: 2.2rem;
            }
          }
          .short-description {
            display: none;
          }
          @media #{$medium-up} {
            width: 55%;
            padding-left: 0;
            .product-name {
              margin-top: 0.8em;
              margin-bottom: 0.8em;
              color: $product-name-link-color;
              font-weight: lighter;
              font-size: 1.2rem;
              line-height: 1.8rem;
            }
            .short-description {
              display: block;
            }
          }
        }
        .price-summary {
          display: flex;
          flex-direction: column;
          width: 100%;
          text-align: center;
          .qty-price-combo {
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom: #cccccc solid 2px;
            @media #{$small-only} {
              padding-right: 1rem;
            }
            .cart-price {
              margin: auto 0;
              padding-left: 1rem;
              &:before {
                padding: 0 0.5rem;
                content: "x";
              }
            }
          }
          input.qty {
            width: 3rem;
            text-align: center;
          }
          .cart-price:last-child {
            font-weight: bold;
            font-size: 1.4rem;
          }
          @media #{$medium-up} {
            width: 30%;
            text-align: right;
            input.qty {
              float: right;
            }
          }
        }
        .actions {
          width: auto;
          height: auto;
        }
        .btn-remove {
          position: absolute;
          top: 0rem;
          right: 0rem;
          padding: 1rem;
          @media #{$medium-up} {
            top: 0rem;
            right: -3rem;
            padding: 0.25rem;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            background: $cart-bg-color;
          }
        }
      }
      .cart-footer {
        display: block;
        padding: 0.5rem 0;
        background: none;
        #empty_cart_button,
        .btn-update {
          color: #3b3e40;
          background: #f0f0f0;
          &:hover {
            background: darken(#f0f0f0, 5%);
          }
        }
        button {
          width: 100%;
        }
        @media #{$medium-up} {
          button {
            width: auto;
            &.btn-update {
              float: right;
              margin: 0;
            }
            &.btn-continue {
              margin-left: 0;
            }
          }
        }
      }
      .link-edit {
        display: none;
      }
      .link-wishlist.use-ajax {
        display: none;
      }
    }

    .item-options {
      display: flex;
      margin: 0.25rem 0;
      .option {
        display: flex;
        margin-right: 1em;
        dt {
          margin-right: 0.2em;
        }
      }
    }

    .cart-table {
      width: 100%;
    }
    .cart-links a {
      margin: 0.3rem;
      padding: 0.5rem;
      color: white;
      font-size: 0.8rem;
      background: $color-polar;
    }
    .btn-remove {
      span {
        display: none;
      }
      .material-icons {
        position: relative;
        top: 0.1em;
      }
    }

    .checkout-types {
      li {
        float: right;
      }
    }

    .cart-total-price-wrapper {
      display: flex;
      padding-left: 1rem;
      text-align: right;
      .cart-totals {
        display: flex;
        flex: 1;
        flex-direction: column;
        float: right;
        padding-bottom: 1.5rem;
        text-transform: uppercase;
        .a-right {
          padding: 0.2rem 0;
          font-size: 1rem;
          text-align: right;
          .price {
            display: block;
            padding-left: 1rem;
            font-weight: lighter;
            font-size: 1.5rem;
          }
        }

        tfoot .a-right .price {
          font-size: 1.8rem;
        }
      }
      #show-discount-form {
        display: inline-block;
        float: right;
        margin-bottom: 1rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

#shopping-cart-totals-table {
  width: 100%;
}

#discount-coupon-form {
  display: none;
  float: right;
  clear: both;
  .discount {
    h2 {
      display: none;
      margin: 0;
    }
    .button-wrapper {
      margin: 0;
    }
  }
}

.crosssell {
  width: 100%;
  .product-name {
    min-height: 4rem;
  }
}

// END IF
}
